import style from './styles.module.scss'
import throttle from 'lodash/throttle'
import { GRAPHIC_TEXT_LOGO, SHOW_MENU_MOBILE_UNDER, VIDZING_HOME_PAGE } from '../../constants'
import { MeMenu } from './me-menu'
import { menuReducer } from './me-menu/menu-reducer'
import { ReactElement, useEffect, useMemo, useReducer, useState } from 'react'
import CloseMenu from '@library/header/me-menu/close-button'
import UseSelectWhiteLabelData from 'hooks/white-label/use-select-white-label-data'
import RenderIf from '@root/utils/render-if'
import { Search } from '@library/header/search'
import { useAppSelector as useSel, useAppSelector } from '@root/store'
import { selectInputVisibility } from '@store/search-slice/_selectors'
import { hasProp } from '@root/utils/has-prop'
import { ButtonWrapper } from '@library/_buttons'
import { WhiteLabelInterface } from '@shared/interfaces/creators/white-label.interface'
import useHasSubscriptionsOrPass from '@hooks/use-has-subscriptions-or-passes'
import { selectUserId } from '@store/user-slice/_selectors'
import { UrlPathsEnum } from '@shared/enums/layout/url-paths.enum'
import { SCROLL_THRESHOLD_TO_TRIGGER_IN_PX } from '@shared/constants/layout/constants'
import { selectAppFeatureFlagStatusByName } from '@store/feature-flag-slice/_selectors'
import { ChannelInterface } from '@shared/interfaces/creators/channel.interface'
import { useSelector } from 'react-redux'
import { isEmptyArray } from '@utils/arrays'
import { selectChannelPropByChannelPathname } from '@store/channel-slice/_selectors'
import { FIND_A_CREATOR_TEXT } from '@components/layout/header/_constants'
import SearchButton from '@ui/search/button'
import useSearch from '@hooks/_search/use-search'
import Hyperlink from '@ui/cta/hyperlink'
import UseLoadOtherHooks from '@components/channel-page/_hooks/use-load-other-hooks'
import { SUBSCRIPTION_PLANS_RAIL_ID } from '@shared/constants/rails.constants'
import usePageWidth from '@hooks/layout/use-page-width'
import { clsx } from 'clsx'
import { isEmptyString } from '@utils/strings'

export type HeaderProps = {
  className?: string
  hasNav?: boolean
  hasSearch?: boolean
  whiteLabel?: WhiteLabelInterface
  channelPathname?: string
  channel?: ChannelInterface
  mobileBreakpoint?: number
}

let previousY = 0

const calculateScrollDistance = () => {
  const distance = window.scrollY - previousY
  previousY = window.scrollY
  return distance
}

export default function Header({
  className = '',
  hasNav = true,
  hasSearch = false,
  whiteLabel,
  channelPathname,
  mobileBreakpoint = SHOW_MENU_MOBILE_UNDER,
}: HeaderProps): ReactElement {
  const [isShowMenu, dispatchMenuAction] = useReducer(menuReducer, false)
  const [isHideHeader, setIsHideHeader] = useState(false)
  const isMobile = usePageWidth(SHOW_MENU_MOBILE_UNDER)
  // Flags
  const mobileChannelHeaderFlag = useAppSelector(
    selectAppFeatureFlagStatusByName('flagBugMobileChannelHeader'),
  )
  // Show background by default if the theme is disabled
  const [addCustomClass, setAddCustomClass] = useState<boolean>(false)
  // Get white label for removing of logo and creators and changing the background
  const { routeToSuperChannel } = UseSelectWhiteLabelData()
  const hasWhiteLabel = !!whiteLabel
  // Use channel pathname from props if available. Otherwise, the header will be on home page or a vidzing page where the below doesn't matter
  const { hasSubscriptionOrPass } = useHasSubscriptionsOrPass(channelPathname)
  const userId = useSel(selectUserId)

  // Checks if cheer dance flag is active
  const isCheerIqFlagActive = useSelector(selectAppFeatureFlagStatusByName('flagCheerdanceIqPage'))
  //

  const categories = useSelector(
    selectChannelPropByChannelPathname(channelPathname)('channelCategories'),
  )

  /**
   * Determinate what path the logo is going to use:
   * 1 - If content or channel has category, go to that category path
   * 2 - If there is no category, go to explore page
   */
  const pathToUse: string = useMemo(() => {
    // If the flag is active and the channel has categories, use the first category to create the path
    if (isCheerIqFlagActive && !isEmptyArray(categories)) {
      const path = categories?.[0]?.channelCategoryCatalog?.slug
      if (path) {
        return `/${path}`
      }
    }
    return UrlPathsEnum.EXPLORE
  }, [isCheerIqFlagActive, categories])

  // Search
  const searchInputVisibility = useAppSelector(selectInputVisibility)
  const { hasSearch: isChannelSearching } = UseLoadOtherHooks({ channelPathname })

  const showHeader = () => {
    setIsHideHeader(false)
  }
  const hideHeader = () => {
    setIsHideHeader(true)
  }

  const addBackgroundColour = () => {
    if (window.scrollY > SCROLL_THRESHOLD_TO_TRIGGER_IN_PX) {
      setAddCustomClass(true)
    } else {
      setAddCustomClass(false)
    }
    return
  }

  /**
   * Hide menu when the user do scroll only on desktop
   */
  const toggleHeader = throttle(() => {
    if (isMobile) return

    addBackgroundColour()

    const distance = calculateScrollDistance()
    if (distance < 0 && isHideHeader) {
      showHeader()
    } else if (!isMobile && distance > 0 && window.scrollY > 380 && !isHideHeader) {
      dispatchMenuAction('hide')
      hideHeader()
    } else if (isMobile && distance > 0 && window.scrollY > 50 && !isHideHeader) {
      dispatchMenuAction('hide')
      hideHeader()
    }
  }, 16)

  useEffect(() => {
    window.addEventListener('scroll', toggleHeader)
    window.addEventListener('resize', showHeader)

    return () => {
      window.removeEventListener('scroll', toggleHeader)
      window.removeEventListener('resize', showHeader)
    }
  }, [toggleHeader])

  const { redirectToSearch } = useSearch()

  const customClassToUse = useMemo(() => {
    if (!addCustomClass) {
      return ''
    } else if (addCustomClass) {
      return style.background
    }
  }, [className, addCustomClass])

  return (
    <header
      className={clsx(style.header, className, customClassToUse, {
        [style.hide]: isHideHeader,
        [style.backgroundV2]: isShowMenu && isMobile,
        [style.customChannelHeader]: hasWhiteLabel && addCustomClass,
        [style.mobileChannelHeader]: mobileChannelHeaderFlag,
      })}
    >
      <RenderIf isTrue={!hasWhiteLabel}>
        <ButtonWrapper href={pathToUse} className={style.logo}>
          <img alt={'Vidzing Logo'} draggable={false} src={GRAPHIC_TEXT_LOGO} />
        </ButtonWrapper>
      </RenderIf>
      <RenderIf isTrue={hasWhiteLabel && hasProp(whiteLabel?.logoUrl)}>
        <ButtonWrapper onClick={routeToSuperChannel} className={style.logo}>
          <img
            alt={'logo'}
            className={style.whiteLabelLogo}
            draggable={false}
            src={whiteLabel?.logoUrl}
          />
        </ButtonWrapper>
      </RenderIf>
      {hasNav && (
        <>
          <nav className={style.nav}>
            <ul className={style.list}>
              <RenderIf isTrue={!hasSearch && !hasWhiteLabel}>
                <li className={style.searchV2}>
                  <SearchButton label={FIND_A_CREATOR_TEXT} onSearch={redirectToSearch} />
                </li>
              </RenderIf>
              <RenderIf isTrue={hasSearch && !isChannelSearching}>
                <li
                  className={clsx({
                    [style.hideBtn]: isShowMenu,
                    [style.searchMenu]: hasSearch && isMobile,
                    [style.move]: searchInputVisibility,
                  })}
                >
                  <Search isMobile={isMobile} />
                </li>
              </RenderIf>
              <RenderIf isTrue={!hasWhiteLabel}>
                <li
                  className={clsx(style.creatorLinkV2Wrapper, {
                    [style.displayNone]: hasSearch && isMobile && searchInputVisibility,
                  })}
                >
                  <Hyperlink
                    external={true}
                    label={'Creators'}
                    path={VIDZING_HOME_PAGE}
                    variant={'primary'}
                    className={style.creatorLinkV2}
                    fullSize
                  />
                </li>
              </RenderIf>
              {/* If white label & offer subscriptions or passes & no user, show a button to link to plans */}
              <RenderIf isTrue={hasWhiteLabel && hasSubscriptionOrPass}>
                <li
                  className={clsx(style.plansButton, {
                    [style.displayNone]: hasSearch && isMobile && searchInputVisibility,
                  })}
                >
                  <Hyperlink
                    external={false}
                    label={'Plans'}
                    path={`/${channelPathname}#${SUBSCRIPTION_PLANS_RAIL_ID}`}
                    variant={'whiteVariant'}
                    fullSize
                  />
                </li>
              </RenderIf>
            </ul>
            <MeMenu
              dispatchMenuAction={dispatchMenuAction}
              isShowMenu={isShowMenu}
              isMobile={isMobile}
            />
          </nav>
          <RenderIf isTrue={isMobile}>
            {isShowMenu && <CloseMenu dispatchMenuAction={dispatchMenuAction} />}
          </RenderIf>
        </>
      )}
    </header>
  )
}
