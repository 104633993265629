import style from './common.module.css'
import type { MenuAction } from './menu-reducer'
import type { Dispatch, ReactElement } from 'react'
import { MeMenuButton } from './menu-button'
import { MeMenuPopUp } from './menu-pop-up'
import { MeMenuPopUpMobile } from './menu-pop-up-mobile'

export type Props = {
  className?: string
  dispatchMenuAction: Dispatch<MenuAction>
  isShowMenu: boolean
  isMobile: boolean
}

export function MeMenu({
  className,
  dispatchMenuAction,
  isShowMenu,
  isMobile,
}: Props): ReactElement {
  return (
    <div className={[style.wrapper, className].join(' ')}>
      <MeMenuButton
        className={isShowMenu ? style.hideBtnWhenMenuIsOpen : ''}
        dispatchMenuAction={dispatchMenuAction}
        isShowMenu={isShowMenu}
      />

      {isMobile ? (
        <MeMenuPopUpMobile isShowMenu={isShowMenu} dispatchMenuAction={dispatchMenuAction} />
      ) : (
        <MeMenuPopUp
          className={style.popUp}
          isShowMenu={isShowMenu}
          dispatchMenuAction={dispatchMenuAction}
        />
      )}
    </div>
  )
}
