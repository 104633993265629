import style from './styles.module.scss'
import type { MenuAction } from '../menu-reducer'
import { BurgerIcon } from './burger-icon'
import { Dispatch, useEffect, useMemo, useRef } from 'react'
import { GenericAvatarIcon } from './generic-avatar-icon'
import { selectUserId, selectUserProps } from '@store/user-slice/_selectors'
import { useAppSelector as useSel } from '@store/index'
import useCheckIfMobile from '@hooks/use-check-if-mobile'
import { SHOW_MENU_MOBILE_UNDER } from '@root/constants'
import useCustomBasicStyles from '@hooks/use-custom-basic-styles'
import splitChannelUrl from '@utils/split-channel-url'
import useGetSignupUrl from '@hooks/use-get-signup-url'
import { useRouter } from 'next/router'
import { MENU_BUTTON } from '@shared/constants/testing-tags.constants'
import { clsx } from 'clsx'

export type Props = {
  className?: string
  dispatchMenuAction: Dispatch<MenuAction>
  isShowMenu: boolean
}

export function MeMenuButton({ className, dispatchMenuAction, isShowMenu }: Props): JSX.Element {
  const refBtn = useRef<HTMLButtonElement>()
  const userId = useSel(selectUserId)
  const email = useSel(selectUserProps('email')) ?? ''
  const name = useSel(selectUserProps('firstName')) ?? ''
  const customStyles = useCustomBasicStyles()

  // Initialize css variables
  const cssVariables: Record<string, unknown> = {
    '--background': customStyles ? customStyles['--customMainColour'] : 'var(--offBlackNew)',
    '--fillColor': customStyles ? 'var(--offBlack)' : 'var(--offBlackNew)',
    '--font-colour-auth': customStyles ? customStyles['--customFormFontColour'] : 'var(--offWhite)',
  }

  const isMobile = useCheckIfMobile(SHOW_MENU_MOBILE_UNDER)

  // White label data
  const router = useRouter()
  const path = router.asPath
  const { channelSlug } = splitChannelUrl(path)
  const { hasWhiteLabel, loginUrl } = useGetSignupUrl(channelSlug)

  /** ON mobile version, we remove the scroll when the menu is opened */
  const toggleMenu = async () => {
    // If white label then the me-menu if guest only has login button so no need to popup just route straight to login
    if (hasWhiteLabel && !userId) {
      router.push(loginUrl)
      return
    }

    if (isMobile) {
      const mainWrapper = document.querySelector('html')
      mainWrapper?.style.setProperty('overflow', 'hidden')
    }

    dispatchMenuAction('toggle')
  }

  const guestButton = (
    <>
      <span className={style.login}>Log in</span>
      {isMobile && !hasWhiteLabel ? (
        <BurgerIcon className={style.icon} />
      ) : (
        <GenericAvatarIcon className={style.icon} />
      )}
    </>
  )

  const authButton = (
    <>
      <BurgerIcon className={style.icon} />
      <span className={style.name}>{(name || email).charAt(0)}</span>
    </>
  )

  useEffect(() => {
    const closeMenuOnClick = (event: MouseEvent) => {
      if (event.target !== refBtn.current) {
        dispatchMenuAction('hide')
      }
    }

    if (isShowMenu) {
      window.addEventListener('click', closeMenuOnClick)
    } else {
      window.removeEventListener('click', closeMenuOnClick)
    }

    return () => {
      window.removeEventListener('click', closeMenuOnClick)
    }
  }, [dispatchMenuAction, isShowMenu])

  return (
    <button
      ref={refBtn}
      className={clsx([className], style.base, userId ? style.authButton : style.guestButton)}
      onClick={toggleMenu}
      style={cssVariables}
      data-id={MENU_BUTTON}
    >
      {userId ? authButton : guestButton}
    </button>
  )
}
